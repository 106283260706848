body {
    background-color: #dadde1;
}

svg {
  max-width: 100%;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.alert-enter .modal-content {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active .modal-content {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit .modal-content {
  opacity: 1;
}
.alert-exit-active .modal-content {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}


.alert-enter .modal-overlay {
  opacity: 0;
}
.alert-enter-active .modal-overlay {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.alert-exit .modal-overlay {
  opacity: 1;
}
.alert-exit-active .modal-overlay {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}


/* DRAWER */
.drawer-enter .modal-content {
  opacity: 0;
  transform: translateX(100%);
}
.drawer-enter-active .modal-content {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.drawer-exit .modal-content {
  opacity: 1;
  transform: translateX(0);
}
.drawer-exit-active .modal-content {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}


.drawer-enter .modal-overlay {
  opacity: 0;
}
.drawer-enter-active .modal-overlay {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.drawer-exit .modal-overlay {
  opacity: 1;
}
.drawer-exit-active .modal-overlay {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}


/* DRAWER BOTTOM  */

.drawerBottom-enter .modal-content {
  opacity: 0;
  transform: translateY(100%);
}
.drawerBottom-enter-active .modal-content {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.drawerBottom-exit .modal-content {
  opacity: 1;
  transform: translateY(0);
}
.drawerBottom-exit-active .modal-content {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 300ms, transform 300ms;
}


.drawerBottom-enter .modal-overlay {
  opacity: 0;
}
.drawerBottom-enter-active .modal-overlay {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.drawerBottom-exit .modal-overlay {
  opacity: 1;
}
.drawerBottom-exit-active .modal-overlay {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}